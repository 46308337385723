import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from "@ngrx/store";
import {authReducer} from "./reducers";
import {AuthService} from "./services/auth.service";
import {RouterModule, Routes} from "@angular/router";
import {ReactiveFormsModule} from "@angular/forms";
import {SpinnerComponent} from "../../shared/components/spinner/spinner.component";
import {InputComponent} from "../../shared/components/input/input.component";
import {ToggleComponent} from "../../shared/components/toggle/toggle.component";
import {TranslocoModule} from "@ngneat/transloco";
import {EffectsModule} from "@ngrx/effects";
import {AuthEffects} from "./auth.effects";

const routes: Routes = [{path: 'login', loadComponent: () => import('./pages/login/login.component').then(m => m.LoginComponent)}];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        ReactiveFormsModule,
        SpinnerComponent,
        InputComponent,
        ToggleComponent,
        TranslocoModule,
        StoreModule.forFeature('auth', authReducer),
        EffectsModule.forFeature([AuthEffects]),
    ]
})

export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        AuthService,
      ]
    }
  }
}

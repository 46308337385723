import { Injectable, inject } from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {AuthService} from "./services/auth.service";
import {Router} from "@angular/router";
import {AuthActions} from "./action-types";
import {tap} from "rxjs";

// va importata solo sull'auth module e non in altri contesti
@Injectable()
export class AuthEffects {
  private actions$ = inject(Actions);
  private authService = inject(AuthService);
  private router = inject(Router);

  // in sostanza createEffect prende in input un observable, si sottoscrive e restituisce un observable. vedi codice costruttore
  login$ = createEffect(() =>
    this.actions$.pipe(
      // ofType è un operatore di rxjs
      ofType(AuthActions.login),
      // tap((action) => {
      //     localStorage.setItem('token', action.token)
      //   }
      // )
    ), {dispatch: false} // non dispatcho l'evento in quando dopo login l'azione resta viva e entrerei in un loop infinito
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout),
      tap(() => {
        // localStorage.removeItem('token');
        // localStorage.removeItem('user');
        // this.authService.logout();
      })
    ), {dispatch: false}
  );


}
